<template>
  <div class="skeleton__wrapper" :class="theme">
    <slot name="skeleton" />
    <Shimmer />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
export default {
  props: {
    theme: {
      type: String,
      default: 'light',
    },
  },
  components: {
    Shimmer: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Shimmer" */ '@/modules/cms/skeletons/Shimmer.vue'
      )
    ),
  },
};
</script>
